import {
  HStack,
  Avatar,
  Box,
  Flex,
  Image,
  Grid,
  Text,
  Progress,
  SimpleGrid,
  Slider,
  SliderTrack,
 
  SliderThumb,
  SliderMark,
  useColorModeValue,
  GridItem,
  Icon,
} from "@chakra-ui/react";
// Assets
import lineChartRed from "assets/img/global/lineChartRed.png";
import lineChartYellow from "assets/img/global/lineChartYellow.png";
import Graph from "assets/img/global/Graph.png";

// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import Card from "components/card/Card";
import NewFollowerTable from "views/admin/analytics/components/NewFollowerTable";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex";

import { BsArrowUpRightCircleFill, BsFillTriangleFill } from "react-icons/bs";
import Chart from "react-apexcharts";
import { useState } from "react";
import { HSeparator } from "components/separator/Separator";

const seriesRadial = [76];

var options1: any = {
  chart: {
    type: "bar",
    height: 350,
  },
  colors: ["#64C8BC", "#EDF8F6", "#19BAEE"],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
  },
  yaxis: {
    title: {
      text: "$ (thousands)",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val: any) {
        return "$ " + val + " thousands";
      },
    },
  },
};

var options_2: any = {
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: "top",
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetX: -6,
    style: {
      fontSize: "12px",
      colors: ["#fff"],
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    shared: true,
    intersect: false,
  },
  xaxis: {
    categories: ["13-17", "18-24", "25-34", "35-44", "45++"],
  },
  colors: ["#19BAEE", "#FF5247"],
};
export default function Index() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [state, setState] = useState<any>({
    optionsRadial: {
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#F4F7F9",
            strokeWidth: "100%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            showOn: "",
            name: {
              offsetY: -20,
              show: false,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              formatter: function (val: any) {
                return val;
              },
              color: "#62E2A6",
              fontSize: "30px",
              show: false,
            },
          },
        },
      },
      fill: {
        colors: [
          function ({ value, seriesIndex, w }: any) {
            if (value < 55) {
              return "#62E2A6";
            } else if (value >= 55 && value < 80) {
              return "#884DFF";
            } else {
              return "#000";
            }
          },
        ],
      },
      stroke: {
        lineCap: "round",
      },
      labels: [""],
    },
    seriesRadial: [43.2],
    seriesRadial2: [55.45],
  });
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem gap={4} colSpan={8} borderRadius="10px">
        <Flex gap={4} justifyContent={"space-between"}>
          <Card>
            <Flex
              direction={"column"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Text fontSize={"38px"} fontWeight="medium">
                260{" "}
              </Text>
              <Text fontSize={"12px"} fontWeight="normal">
                Total Posts
              </Text>
              <HStack mt="30px">
                <Icon
                  as={BsArrowUpRightCircleFill}
                  color={"#64C8BC"}
                  cursor="pointer"
                  boxSize={"30px"}
                  // size={"sm"}
                  //    p='5px'
                  borderRadius={"5px"}
                />

                <Text fontSize={"23px"} fontWeight="normal" color="#64C8BC">
                  26.84%
                </Text>
              </HStack>
            </Flex>
          </Card>

          <Card>
            <Flex
              direction={"column"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Text fontSize={"38px"} fontWeight="medium">
                2,041
              </Text>
              <Text fontSize={"12px"} fontWeight="normal">
                Followers
              </Text>
              <HStack mt="30px">
                <Icon
                  as={BsArrowUpRightCircleFill}
                  color={"#64C8BC"}
                  cursor="pointer"
                  boxSize={"30px"}
                  // size={"sm"}
                  //    p='5px'
                  borderRadius={"5px"}
                />

                <Text fontSize={"23px"} fontWeight="normal" color="#64C8BC">
                  26.84%
                </Text>
              </HStack>
            </Flex>
          </Card>
          <Card>
            <Flex
              direction={"column"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Text fontSize={"38px"} fontWeight="medium">
                351
              </Text>
              <Text fontSize={"12px"} fontWeight="normal">
                Friends
              </Text>
              <HStack mt="30px">
                <Icon
                  as={BsArrowUpRightCircleFill}
                  color={"#64C8BC"}
                  cursor="pointer"
                  boxSize={"30px"}
                  // size={"sm"}
                  //    p='5px'
                  borderRadius={"5px"}
                />

                <Text fontSize={"23px"} fontWeight="normal" color="#64C8BC">
                  26.84%
                </Text>
              </HStack>
            </Flex>
          </Card>
          <Card>
            <Flex
              direction={"column"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Text fontSize={"38px"} fontWeight="medium">
                82.6%
              </Text>
              <Text fontSize={"12px"} fontWeight="normal">
                Engagement
              </Text>
              <HStack mt="30px">
                <Icon
                  as={BsArrowUpRightCircleFill}
                  color={"#64C8BC"}
                  cursor="pointer"
                  boxSize={"30px"}
                  // size={"sm"}
                  //    p='5px'
                  borderRadius={"5px"}
                />

                <Text fontSize={"23px"} fontWeight="normal" color="#64C8BC">
                  26.84%
                </Text>
              </HStack>
            </Flex>
          </Card>
        </Flex>
        <Flex gap={4} justifyContent={"space-between"}>
          <Card mt="20px" borderRadius={"10px"}>
            <Text fontSize={"17px"} fontWeight="semibold">
              Profile Growth & Discovery
            </Text>
            <Text fontSize={"12px"} fontWeight="normal">
              See insights on how your profile has grown and changed over time
            </Text>
            <Flex justifyContent={"center"} alignItems="center">
              <Box w="60%">
                <HStack mt="10px">
                  <Icon
                    as={BsFillTriangleFill}
                    color={"#64C8BC"}
                    cursor="pointer"
                    boxSize={"16px"}
                    // size={"sm"}
                    //    p='5px'
                    borderRadius={"5px"}
                  />

                  <Text fontSize={"20px"} fontWeight="normal" color="#64C8BC">
                    8.29%
                  </Text>
                </HStack>
                <Text fontSize={"12px"} fontWeight="normal">
                  from prev. month
                </Text>
              </Box>
              <Flex justifyContent={"space-between"} w="40%">
                <Box>
                  <Text fontSize={"20px"} fontWeight="medium">
                    2.648
                  </Text>
                  <Text fontSize={"12px"} color="#7D8DA1" fontWeight="normal">
                    Impressions
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={"20px"} fontWeight="medium">
                    2.648
                  </Text>
                  <Text fontSize={"12px"} color="#7D8DA1" fontWeight="normal">
                    Reach
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={"20px"} fontWeight="medium">
                    2.648
                  </Text>
                  <Text fontSize={"12px"} color="#7D8DA1" fontWeight="normal">
                    Growth Rate
                  </Text>
                </Box>
              </Flex>
            </Flex>

            <Flex justifyContent={"center"} alignItems="center">
              <Chart
                width="900"
                options={options1}
                series={[
                  {
                    name: "Impressions",
                    data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
                  },
                  {
                    name: "Reach",
                    data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
                  },
                  {
                    name: "Growth Rate",
                    data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
                  },
                ]}
                type="bar"
                height={350}
              />
            </Flex>
          </Card>
        </Flex>
        {/* <Flex mt="20px" gap={4} justifyContent={"space-between"}>
          <NewFollowerTable tableData={tableDataComplex} />
        </Flex> */}
      </GridItem>
      <GridItem gap={4} colSpan={4} borderRadius="10px">
        <Card w="100%" borderRadius={"10px"}>
          <Text fontSize={"17px"} fontWeight="semibold">
            Age Range Visits
          </Text>
          <HStack>
            <Text color={"#19BAEE"}>Male</Text>
            <HSeparator w="2px" h="20px" />
            <Text color={"#FF5247"}>Female</Text>
          </HStack>

          <Text mt="20px" fontSize={"17px"} fontWeight="semibold">
            Gender Demographic
          </Text>

          <Flex justifyContent={"center"} alignItems="center">
            <Chart
              width="500"
              type="bar"
              height={430}
              options={options_2}
              series={[
                { name: "Male", data: [44, 55, 41, 64, 22] },
                { name: "Female", data: [53, 32, 33, 52, 13] },
              ]}
            />
          </Flex>
        </Card>

        <Card mt="20px">
          <Flex direction={"column"}>
            <Text fontSize={"16px"} fontWeight="normal">
              Country Engagement
            </Text>
            <HStack mt="20px" justifyContent={"space-between"} >
              <Text fontSize={"23px"} fontWeight="normal">
                45.5%
              </Text>
              <Image src={Graph} />
              
            </HStack>

            <HStack mt="30px">
              <Icon
                as={BsArrowUpRightCircleFill}
                color={"#64C8BC"}
                cursor="pointer"
                boxSize={"16px"}
                // size={"sm"}
                //    p='5px'
                borderRadius={"5px"}
              />

              <Text fontSize={"16px"} fontWeight="normal" color="#64C8BC">
                United States
              </Text>
            </HStack>
            <HStack justifyContent={"space-between"} mt="20px">
              <Text fontSize={"16px"} fontWeight="normal">
                United States
              </Text>
              <Text fontSize={"16px"} fontWeight="normal">
                15.5%
              </Text>
            </HStack>
            <HStack justifyContent={"space-between"} mt="20px">
              <Text fontSize={"16px"} fontWeight="normal">
                United States
              </Text>
              <Text fontSize={"16px"} fontWeight="normal">
                15.5%
              </Text>
            </HStack>
            <HStack justifyContent={"space-between"} mt="20px">
              <Text fontSize={"16px"} fontWeight="normal">
                United States
              </Text>
              <Text fontSize={"16px"} fontWeight="normal">
                15.5%
              </Text>
            </HStack>
            <HStack justifyContent={"space-between"} mt="20px">
              <Text fontSize={"16px"} fontWeight="normal">
                United States
              </Text>
              <Text fontSize={"16px"} fontWeight="normal">
                15.5%
              </Text>
            </HStack>
          </Flex>
        </Card>
      </GridItem>
    </Grid>
  );
}
