import React, { useMemo, memo } from "react";
import { HiOutlineGlobe, HiOutlineChevronRight } from "react-icons/hi";
import { IconType } from "react-icons";
import {
  useDisclosure,
  Avatar,
  Flex,
  Text,
  HStack,
  Icon,
  Box,
  Button,
  chakra,
} from "@chakra-ui/react";



import Card from "components/card/Card";
interface Props {
  cardBg: string;
  heading: string;
  handleNext?: () => void;
  iconName: IconType;
  [x: string]: any;
  btn_1_txt: string;
  btn_2_txt: string;
  image: any;
  rank: string;
  clicks: string;
}
function GamesCard(props: Props) {
  const textColorSecondary = "gray.400";
  const {
    cardBg,
    heading,
    iconName,
    btn_1_txt,
    btn_2_txt,
    handleNext,
    image,
    rank,
    clicks,
    ...rest
  } = props;

  return (
    <Card
      w="48%"
      border={"1px solid #E3E4E5"}
      boxShadow={"xl"}
      bg={cardBg}
      p="10px"
      mt="20px"
      borderRadius={"8px"}
      {...rest}
    >
      <Flex py="10px" alignItems={"center"}>
        <HStack w="100%">
          <Box>
            <Avatar size="md" name="Kola Tioluwani" src={image} />{" "}
          </Box>
          <HStack w="full" justifyContent={"space-between"}>
            <Box>
              <Text fontSize={"16px"}>{heading}</Text>
              <Text color={textColorSecondary} fontSize={"15px"}>
                {" "}
                Ranking:{" "}
                <chakra.span color={"black"}>{rank}</chakra.span>{" "}
              </Text>
            </Box>
            <Box>
              <Text color={"#31D0AA"} fontSize={"15px"}>
                {" "}
                {clicks} clicks
              </Text>
            </Box>
          </HStack>
        </HStack>
      </Flex>
    </Card>
  );
}
export default memo(GamesCard);
