import React, { useState, memo } from "react";
// Chakra imports
import {
  Input,
  Grid,
  Flex,
  Image,
  Text,
  GridItem,
  InputGroup,
  Box,
  Icon,
  HStack,
  Button,
  Avatar,
} from "@chakra-ui/react";

import { AiOutlineFilePdf } from "react-icons/ai";

import { VSeparator } from "components/separator/Separator";
import MainMenu from "components/menu/MainMenu";

import pubg_rec from "assets/img/global/pubg_rec.png";
import Card from "components/card/Card";

// Assets
import banner from "assets/img/global/pubg_banner2.png";
import avatar from "assets/img/global/avatar.png";
import avatar7 from "assets/img/global/avatar7.png";

interface Props {
  tabIndex: number;
  handleTabsChange: any;
}

function Header(props:any) {
  const {handleTabsChange} =props
  // Chakra Color Mode

  //
  const buttonColorBrand = "blue.200";

  const textColorSecondary = "#96A5B8";
  const textColorSecondary_2 = "gray.300";

  return (
    <Grid alignItems="center" templateColumns="repeat(12, 1fr)" gap={8}>
      <GridItem py="20px" colSpan={4}>
        <Flex w="80%" justifyContent={"space-between"} alignItems="center">
          <Text
          onClick={()=>handleTabsChange(0)}
            cursor={"pointer"}
            _hover={{ color: buttonColorBrand }}
            fontSize={"17px"}
            color={textColorSecondary}
          >
            Overview
          </Text>
          <VSeparator w="2px" h="20px" />
          <Text
           onClick={()=>handleTabsChange(1)}
            cursor={"pointer"}
            _hover={{ color: buttonColorBrand }}
            color={textColorSecondary}
            fontSize={"17px"}
          >
            Social
          </Text>
          <VSeparator w="2px" h="20px" />
          <Text
           onClick={()=>handleTabsChange(2)}
            cursor={"pointer"}
            _hover={{ color: buttonColorBrand }}
            color={textColorSecondary}
            fontSize={"17px"}
          >
            Events
          </Text>
          <VSeparator w="2px" h="20px" />
          {/* <Text
           onClick={()=>handleTabsChange(3)}
            cursor={"pointer"}
            _hover={{ color: buttonColorBrand }}
            color={textColorSecondary}
            fontSize={"17px"}
          >
            GRID
          </Text> */}
        </Flex>
      </GridItem>
      <GridItem    py="20px" colSpan={4}>
        <Flex w="100%" justifyContent={"flex-end"}>
          <Card w='70%' py="10px" px="20px" borderRadius={"5px"} boxShadow="md" >
            <Flex justifyContent={"space-between"} alignItems="center">
              <Text
                cursor={"pointer"}
                _hover={{ color: buttonColorBrand }}
                fontSize={"14px"}
              >
                Today
              </Text>
              <VSeparator w="2px" h="20px" />
              <Text
                cursor={"pointer"}
                _hover={{ color: buttonColorBrand }}
                fontSize={"14px"}
              >
                7 Days
              </Text>
              <VSeparator w="2px" h="20px" />
              <Text
                cursor={"pointer"}
                _hover={{ color: buttonColorBrand }}
                fontSize={"14px"}
              >
                1 Month
              </Text>
              <VSeparator w="2px" h="20px" />
              <Text
                cursor={"pointer"}
                _hover={{ color: buttonColorBrand }}
                fontSize={"14px"}
              >
                3 Months
              </Text>
            </Flex>
          </Card>
        </Flex>
      </GridItem>
      <GridItem py="20px" colSpan={4}>
        <Flex justifyContent={"flex-end"}>
          <Flex w="70%" justifyContent={"space-between"} alignItems="center">
            <Icon
              as={AiOutlineFilePdf}
              _hover={{ color: buttonColorBrand }}
              color={"#0095FF"}
              cursor="pointer"
              boxSize={"20px"}
              borderRadius={"5px"}
            />
            <Text cursor={"pointer"} color={"#0095FF"} fontSize={"12px"}>
              Export to PDF
            </Text>

            <VSeparator w="2px" h="20px" />
            <Icon
              as={AiOutlineFilePdf}
              color={"#FF708D"}
              cursor="pointer"
              boxSize={"20px"}
              borderRadius={"5px"}
            />
            <Text cursor={"pointer"} color={"#FF708D"} fontSize={"12px"}>
              Save Report
            </Text>
            <VSeparator w="2px" h="20px" />
            <Icon
              as={AiOutlineFilePdf}
              color={"#884DFF"}
              cursor="pointer"
              boxSize={"20px"}
              borderRadius={"5px"}
            />
            <Text cursor={"pointer"} color={"#884DFF"} fontSize={"12px"}>
              Send to Email
            </Text>
          </Flex>
        </Flex>
      </GridItem>
    </Grid>
  );
}

export default memo(Header);
