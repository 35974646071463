import React, { useState, useCallback, useEffect } from "react";
import { getProfile } from "redux/slices/Auth";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  GridItem,
  useColorModeValue,
  SimpleGrid,
  useDisclosure,
  Text,
} from "@chakra-ui/react";

import Header from "views/admin/analytics/components/Header";
import Overview from "views/admin/analytics/Overview";
import Social from "views/admin/analytics/Social";
import Events from "views/admin/analytics/Events";
import { getGameList } from "redux/slices/Events";
import { getAllLocations, getAminitiesList } from "redux/slices/Auth";
import {
  getPrivacyPolicy,
  getTermsConditions,
} from "redux/slices/AdminsTableLists";

export default function Index() {
  const dispatch: AppDispatch = useDispatch();
  // Chakra Color Mode
  const [tabIndex, setTabIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getAllLocations());
    dispatch(getGameList());
    dispatch(getAminitiesList());
    dispatch(getPrivacyPolicy());
    dispatch(getTermsConditions());
  }, []);
  const handleTabsChange = useCallback(
    (index: number) => {
      setTabIndex(index);
    },
    [tabIndex]
  );
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* <Flex w={"full"} h="80vh" justifyContent={"center"} >
        <Text fontSize={'5xl'}>Coming soon!</Text>
      </Flex> */}
      <Text fontSize={`4xl`} fontWeight={`bold`}>Texas High School League</Text>
      <Header handleTabsChange={handleTabsChange} />

      {tabIndex===0 &&  <Overview />}
      {tabIndex === 1 && <Social />}
      {tabIndex === 2 && <Events />}
    </Box>
  );
}
