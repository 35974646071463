import React, { useState, memo } from "react";
// Chakra imports
import {
  Button,
  Grid,
  Flex,
  Avatar,
  Text,
  Tabs,
  TabList,
  Box,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import {
  HiOutlineClock,
  HiOutlineLocationMarker,
  HiCurrencyDollar,
  HiFire,
  HiGlobe,
} from "react-icons/hi";
import { BsFillTagFill } from "react-icons/bs";
import { RiMessageLine ,RiShareForwardFill} from "react-icons/ri";
import { CiBookmark} from "react-icons/ci";




// Assets
import banner from "assets/img/global/pubg_banner2.png";
import avatar from "assets/img/global/avatar.png";
interface Props {
  tabIndex: number;
  handleTabsChange: any;
}
function EventDetailsBanner() {
  // Chakra Color Mode

  //
  const buttonColorBrand = "blue.200";

  const textColorSecondary = "gray.200";
  const textColorSecondary_2 = "gray.300";

  return (
    <Box bg="#F7F8FA">
      <Grid
        bgImage={banner}
        bgSize="cover"
        position="relative"
        h={"100px"}
        borderTopRadius="10px"
      >
        {" "}
        <Flex>
          <Flex
            h={"100px"}
            w="20%"
            borderTopLeftRadius="10px"
            bg="#1C2A5B"
            direction={"column"}
            alignItems="center"
            justifyContent={"center"}
          >
            <Text color="white" fontWeight={"semibold"}>
              Apr
            </Text>
            <Text color="white" fontWeight={"semibold"}>
              7
            </Text>
          </Flex>
          <Flex justifyContent={"flex-end"} alignItems="flex-end" w="80%">
            <HStack
              justifyContent={"space-between"}
              alignItems="center"
              borderTopLeftRadius={"10px"}
              bg={"#31D0AA"}
              w="85px"
              px="20px"
              h={"28px"}
            >
              <Icon
                as={BsFillTagFill}
                color={"white"}
                cursor="pointer"
                boxSize={"17px"}
              />
              <Text fontSize={"17px"} color="white">
                Free
              </Text>
            </HStack>
          </Flex>
        </Flex>
      </Grid>
      <HStack
        p="20px"
        justifyContent={"flex-start"}
        alignItems="center"
        borderTopLeftRadius={"10px"}
      >
        <Text color="#043057" fontSize={"17px"} fontWeight="semibold">
          PUBG CHAMPIONSHIP
        </Text>
        <Icon
          as={HiGlobe}
          ml="20px"
          color="#043057"
          cursor="pointer"
          boxSize={"30px"}
        />
      </HStack>
      <Flex >
        <HStack
          justifyContent={"flex-start"}
          alignItems="center"
          borderTopLeftRadius={"10px"}
        >
          <Icon
            as={HiOutlineLocationMarker}
            ml="20px"
            color="#fff"
            cursor="pointer"
            boxSize={"30px"}
            bg="#298BE2"
            borderRadius={"8px"}
            p="5px"
          />
          <Text color="#043057" fontSize={"14px"}>
            Online
          </Text>
        </HStack>
        <HStack
          justifyContent={"flex-start"}
          alignItems="center"
          borderTopLeftRadius={"10px"}
        >
          <Icon
            as={HiOutlineClock}
            ml="20px"
            color="#fff"
            cursor="pointer"
            boxSize={"30px"}
            bg="#298BE2"
            borderRadius={"8px"}
            p="5px"
          />
          <Text color="#043057" fontSize={"14px"}>
            Mon, 1:36 pm
          </Text>
        </HStack>
      </Flex>
      <Flex p='20px' mt="20px">
        <HStack justifyContent={'space-between'} w="60%">
          <HStack
            justifyContent={"space-between"}
            alignItems="center"
            borderRadius={"10px"}
            bg={"#F15B40"}
            w="85px"
            px="10px"
            h={"36px"}
          >
            <Icon
              as={HiFire}
              color={"white"}
              cursor="pointer"
              boxSize={"26px"}
            />
            <Text fontSize={"17px"}  color="white">
             143
            </Text>
          </HStack>
          <HStack
            justifyContent={"space-between"}
            alignItems="center"
            borderRadius={"10px"}
            bg={"#fff"}
            boxShadow='lg'
            w="68px"
            px="10px"
            h={"36px"}
          >
            <Icon
              as={RiMessageLine}
              color={"#707997"}
              cursor="pointer"
              boxSize={"20px"}
            />
            <Text fontSize={"17px"}  color="#707997">
             143
            </Text>
          </HStack>
          <HStack
                      justifyContent={"center"}
            alignItems="center"
            borderRadius={"10px"}
            bg={"#fff"}
            boxShadow='lg'
            w="50px"
            px="10px"
            h={"36px"}
          >
            <Icon
              as={RiShareForwardFill}
              color={"#707997"}
              cursor="pointer"
              boxSize={"20px"}
            />
          
          </HStack>
        </HStack>
        <HStack justifyContent={'flex-end'} w="40%">
         
          <HStack
            justifyContent={"center"}
            alignItems="center"
            borderRadius={"10px"}
            bg={"#fff"}
            boxShadow='lg'
            w="50px"
            px="10px"
            h={"36px"}
          >
            <Icon
              as={CiBookmark}
              color={"#707997"}
              cursor="pointer"
              boxSize={"20px"}
            />
          
          </HStack>
        </HStack>
      </Flex>
    </Box>
  );
}

export default memo(EventDetailsBanner);
