/* eslint-disable */

import React, { useState, useMemo, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  useToast,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Image,
  PinInput,
  PinInputField,
  HStack,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import Countdown from "react-countdown";

import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import logo from "assets/img/global/logo.png";
import { Authenticate } from "redux/slices/Auth";
import { useDispatch, useSelector } from "react-redux";
import { get } from "redux/services/api";
import { rootUrl } from "utilities/constants";

function millisToMinutesAndSeconds(millis: any) {
  var minutes = Math.floor(millis / 60000);
  var seconds: any = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}
function CodeVerification(props: any) {
  const {  email } = (props.location && props.location.state) || {};
  const toast = useToast();
  const history = useHistory();
  const [countDown, setCountDown] = React.useState(true);

 

  const [countdownkey, setcountdownkey] = React.useState(
    Math.random().toString()
  );
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("blue.100", "white");
  const buttonColorBrand = useColorModeValue("blue.200", "white");
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    pinCode: "",
   
  });

  const [invalidState, setInvalidState] = useState({
    pinCodeInvalid: false,
  });

  useEffect(()=>{
if(email===""|| email===null|| email===undefined){
  history.push({
    pathname: "/auth/sign-in",
  });
}
  },[email])
  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = () => {
    if (state.pinCode === "" && state.pinCode.length < 6) {
      setInvalidState((prvState) => ({ ...prvState, pinCodeInvalid: true }));
      return false;
    }

    return true;
  };
  const submit = async () => {
    if (checks()) {
      setIsLoading(true);
      let url = `${rootUrl}api/v1/account/validate-accesscode?userName=${email}&accessCode=${state.pinCode}&accountType=hub`;
      const response: any = await get(url);
      if (response.status === 200 && response.data.statusCode === 200) {
        console.log({ userId: response.data.response.newId });
        toast.closeAll()
        toast({
          title: "Varified",
          description: response.data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        history.push({
          pathname: "/auth/ResetPassword",

          state: { userId: response.data.response.newId },
        });
      } else {
        toast({
          title: "Failed",
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  const resend = async () => {
    setcountdownkey(Math.random.toString());
    setCountDown(true);
    let url = `${rootUrl}api/v1/account/forgotpassword-send-accesscode?details=${email}&IsSms=false&IsEmail=true&accountType=hub`;
    const response: any = await get(url);
    if (response.status === 200 && response.data.statusCode === 200) {
      toast.closeAll()
      toast({
        title: "Sent",
        description: response.data.message,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
     
    } else {
      toast.closeAll()
      toast({
        title: "Failed",
        description: response.data.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Box>
        <Box w={"full"} height="80px">
          <Box px={{ "2xl": "120px" }} maxW="1440px" m="auto">
            <Image src={logo} alt="logo" w={"107px"} h={"50px"} />
          </Box>
        </Box>

        <Flex
          w="578px"
          m="auto"
          h="100%"
          alignItems="center"
          // bg="red.700"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt="36px"
          p='20px'
          flexDirection="column"
          border={'1px solid #E3E4E5'}
          borderRadius='24px'
        >
          <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            // mb={{ base: "30px", md: "60px" }}
            // px={{ base: "25px", md: "0px" }}
            // mt={{ base: "40px", md: "14vh" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Heading color={textColor} fontWeight="700" fontSize="30px" w={400} mb="10px">
              Enter Security Code{" "}
              </Heading>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <FormControl mt={5}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="light"
                  color={textColorSecondary}
                  mb="8px"
                >
                  Please enter the 6-digits verification code that was sent to
                  your email. This code is valid for 30 minutes.
                </FormLabel>
                <HStack mt="20px">
                  <PinInput
                    value={state.pinCode}
                    onChange={(e) => updateState(e, "pinCode")}
                    size={"lg"}
                    placeholder=""
                    variant="outline"
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                {invalidState.pinCodeInvalid && (
                  <Text mt={"20px"} color={"red"}>
                    {" "}
                    Please enter the 6-digits verification code{" "}
                  </Text>
                )}
              </FormControl>
              <Flex
          justifyContent="flex-start"
          alignItems="center"
          maxW="100%"
          mt="30px"
        >
          <Button
            onClick={resend}
            cursor={"pointer"}
            disabled={countDown}
            color={countDown ? textColorSecondary : textColorBrand}
            as="span"
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            m="0px"
            p="0"
            mr="5px"
            fontWeight="500"
            justifyContent="space-between"
          >
            <Text textDecorationLine={"underline"}>
              Didn’t Received The Code?
            </Text>
          </Button>
          <>
            {useMemo(() => {
             return <Countdown
                key={countdownkey}
                date={Date.now() + 180000}
                intervalDelay={1000}
                precision={0}
                onComplete={() => setCountDown(false)}
                renderer={(props: any) => (
                  <div>{millisToMinutesAndSeconds(props.total)}</div>
                )}
              />;
            }, [countdownkey])}
          </>
        </Flex>

              <Button
                isLoading={isLoading}
                loadingText="Verifying.."
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="50%"
                // float={'right'}
                display={'flex'}
                justifySelf='flex-end'
                alignSelf={'flex-end'}
                h="50"
                mt="50px"
                mb="24px"
                borderRadius={"12px"}
                bg={buttonColorBrand}
                onClick={submit}
              >
                Next
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default CodeVerification;
