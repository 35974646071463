/* eslint-disable */

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  chakra,
  FormErrorMessage,
  useToast,
  Image,
  Center,
  HStack,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import OneSignal from "react-onesignal";
import { Authenticate, clearResults } from "redux/slices/Auth";
import { useDispatch, useSelector } from "react-redux";
import logo from "assets/img/global/logo.png";
import newlogo from "assets/img/global/16_PNG-logo.png";
import { Navigation, Pagination, Autoplay, A11y } from "swiper/modules";
 

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const FeaturesArr = [
  {
    image: require("../../../assets/test/analytics.webp"),
  },
  {
    image: require("../../../assets/test/profile.webp"),
  },
  {
    image: require("../../../assets/test/bracket.webp"),
  },
];
function SignIn() {
  const toast = useToast();

  // Chakra color mode
  const dispatch: any = useDispatch();
  const { userId, userName, email, phoneNumer, token, loading, AuthError } =
    useSelector((state: any) => state.Auth);
  const textColor = useColorModeValue("black.50", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("#298BE2", "#298BE2");
  const buttonColorBrand = useColorModeValue("blue.200", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [state, setState] = useState({
    userName: "",
    password: "",
    playerId: "",
    rememberMe: false,
  });

  useEffect(() => {
    if (AuthError) {
      dispatch(clearResults());
      toast.closeAll()
      toast({
        title: "Auth Faild",
        description: AuthError,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [AuthError, dispatch, toast]);

  useEffect(() => {
    
    initOneSignal();
  }, [userId, userName, email, phoneNumer, token]);

  useEffect(() =>{
        const savedCredentials = JSON.parse(localStorage.getItem("rememberMe") || "{}");
        if (savedCredentials?.userName && savedCredentials?.password) {
          setState((prev) => ({
            ...prev,
            userName: savedCredentials.userName,
            password: savedCredentials.password,
            rememberMe: true,
          }));
        }
  },[])

  const [invalidState, setInvalidState] = useState({
    userNameInvalid: false,

    PasswordInvalid: false,
  });
  const updateState = (val: any, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = () => {
    if (state.userName === "") {
      setInvalidState((prvState) => ({ ...prvState, userNameInvalid: true }));
      return false;
    }
    if (state.password.length < 6) {
      setInvalidState((prvState) => ({ ...prvState, PasswordInvalid: true }));
      return false;
    }

    return true;
  };

  const createAccount = () => {
    if (checks()) {
      if (state.rememberMe) {
        // Save credentials in localStorage
        localStorage.setItem(
          "rememberMe",
          JSON.stringify({ userName: state.userName, password: state.password })
        );
      } else {
        // Clear saved credentials if "Remember Me" is unchecked
        localStorage.removeItem("rememberMe");
      }

      const payload = {
        userName: state.userName,
        password: state.password,
        playerId: "", // Example field, update as necessary
      };
      dispatch(Authenticate(payload));
    }
  };

  const initOneSignal = async () => {
    try {
      OneSignal.init({
        appId: "f11b979e-3baa-42f0-968b-0f7538366b4a",
        safari_web_id:
          "web.onesignal.auto.42873e37-42b9-4e5d-9423-af83e9e44ff4",
        welcomeNotification: {
          disable: false,
          title: "Welcome",
          message: "Welocme to Grid Platform",
        },

        notifyButton: {
          enable: true,
        },
      }).then(async () => {
        OneSignal.Slidedown.promptPush();
        OneSignal.Notifications.setDefaultUrl("https://adminweb.stage.gridgamers.com/");
        OneSignal.Notifications.setDefaultTitle("Powered by Grid!");
        const isSupported = OneSignal.Notifications.isPushSupported();
        let permission = OneSignal.Notifications.permission; // boolean
        OneSignal.Notifications.requestPermission();
        let PushSubscriptionId = OneSignal.User.PushSubscription.id;
        let PushSubscriptionToken = OneSignal.User.PushSubscription.token;
        updateState(PushSubscriptionId, "playerId");
        console.log({
          isSupported,
          permission,
          PushSubscriptionId,
          PushSubscriptionToken,
          playerId: "playerId",
        });
      });
    } catch (e) {
      console.log({ e });
    }
  };
  
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        pl={{base:"0px", "2xl": "120px", "3xl": "120px" }}
        justifyContent="space-between"
        flexDirection="row"
      >
        <Box w="470px"  >
          <Image
            // mt="54px"
            mt={{
              sm: "15px",
              "2sm": "15px",
              md: "15px",
              lg: "20px",
              xl: "25px",
              "2xl": "28px",
              "3xl": "36px",
            }}
            src={newlogo}
            alt="logo"
            //  w={"145px"}
            //  h={"68px"}
            h={{
              sm: "15px",
              "2sm": "15px",
              md: "18px",
              lg: "28px",
              xl: "38px",
              "2xl": "58px",
              "3xl": "68px",
            }}
            w={{
              sm: "65px",
              "2sm": "75px",
              md: "85px",
              lg: "95px",
              xl: "115px",
              "2xl": "125px",
              "3xl": "145px",
            }}
          />
          <Box
            mt={{
              sm: "15px",
              "2sm": "15px",
              md: "15px",
              lg: "20px",
              xl: "25px",
              "2xl": "80px",
              "3xl": "133px",
            }}
            me="auto"
          >
            <Heading
              color={textColor}
              w="470px"
              // fontSize="36px"
              fontSize={{
                sm: "15px",
                "2sm": "15px",
                md: "15px",
                lg: "20px",
                xl: "25px",
                "2xl": "28px",
                "3xl": "36px",
              }}
              mb="10px"
            >
              Welcome to the new wave of
              <chakra.span color={textColorBrand}> eSports</chakra.span>{" "}
            </Heading>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
            mt="26px"
          >
            <FormControl isInvalid={invalidState.userNameInvalid}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
              >
                E-mail Address<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                borderColor={invalidState.userNameInvalid ? "red" : null}
                onChange={(e) => updateState(e.target.value, "userName")}
                value={state.userName}
                placeholder="E-mail Address"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                borderRadius={8}
                mt="10px"
                fontWeight="500"
                size="lg"
              />
              {invalidState.userNameInvalid && (
                <FormErrorMessage>E-mail address is required.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={invalidState.PasswordInvalid}>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                mt="15px"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  borderColor={invalidState.PasswordInvalid ? "red" : null}
                  onChange={(e) => updateState(e.target.value, "password")}
                  value={state.password}
                  borderRadius={8}
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Min. 6 characters"
                  mt="10px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                />

                <InputRightElement display="flex" alignItems="center" mt="15px">
                  <Icon
                    borderColor={invalidState.PasswordInvalid ? "red" : null}
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {invalidState.PasswordInvalid && (
                <FormErrorMessage>
                  Password must contain at least 6 characters .
                </FormErrorMessage>
              )}
              <Flex
                justifyContent="space-between"
                align="center"
                mt="20px"
                mb="24px"
              >
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brandScheme"
                    me="10px"
                    isChecked={state.rememberMe}
                    onChange={(e) => updateState(e.target.checked, "rememberMe")}
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColorSecondary}
                    fontSize="sm"
                  >
                    Remember my password
                  </FormLabel>
                </FormControl>
                <NavLink to="/auth/ForgotPassword">
                  <Text
                    color={buttonColorBrand}
                    fontSize="sm"
                    w="124px"
                    textDecorationLine={"underline"}
                    fontWeight="500"
                  >
                    Forgot Password?
                  </Text>
                </NavLink>
              </Flex>
              <Button
                isLoading={loading}
                loadingText="Sigining in..."
                onClick={createAccount}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                borderRadius={8}
                bg={buttonColorBrand}
              >
                Login
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                Don’t have an account?
                <NavLink to="/auth/sign-up">
                  <Text
                    color={buttonColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    Sign Up
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box
          overflow="hidden"
          borderRadius={"26px"}
          bg="linear-gradient(205.41deg, #1E75FF 0%, #19BAEE 100%)"
          // w="500px"
          w={{ base: "500px", "2xl": "600px", "3xl": "700px" }}
          h={{ base: "700px", "2xl": "650px", "3xl": "800px" }}
          justifyContent={"center"}
          alignItems="center"
          position={"relative"}
          py="40px"
        >
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={48}
            slidesPerView={1}
          >
            {FeaturesArr.map((data, i) => {
              return (
                <SwiperSlide key={i}>
                  <Flex justifyContent={"center"} alignItems="center">
                    <Box mt='40px'>
                      <Image
                        src={data.image}
                        alt=""
                        // width={"200px"}
                        // height={"400px"}
m='auto'
                        width={{
                          base: "90%",
                          "2xl": "90%",
                          "3xl": "90%",
                        }}
                        height={{
                          base: "100%",
                          "2xl": "100%",
                          "3xl": "100%",
                        }}
                      />
                    </Box>
                  </Flex>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Box m="auto" maxW="470px">
            <Text
              fontWeight="bold"
              textAlign={"center"}
              fontSize={{
                sm: "15px",
                "2sm": "15px",
                md: "15px",
                lg: "20px",
                xl: "25px",
                "2xl": "40px",
                "3xl": "48px",
              }}
              color="white"
            >
              Where Hubs are the winners!
            </Text>
            <Text
              textAlign={"center"}
              mt={"10px"}
              // fontSize={"16px"}
              fontSize={{
                sm: "8px",
                "2sm": "9px",
                md: "10px",
                lg: "10px",
                xl: "12px",
                "2xl": "12px",
                "3xl": "16px",
              }}
              color="white"
            >
              The first mobile, gaming events management platform
            </Text>
          </Box>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
