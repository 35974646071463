type RowObj = {
  name: string;
  userName: string;
  email: string;
  phone: string;
  role: string;
  status: string;
};

const tableDataComplex: RowObj[] = [
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Active",
  },
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Inactive",
  },
   
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Active",
  },
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Inactive",
  },
   
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Active",
  },
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Inactive",
  },
   
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Active",
  },
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Inactive",
  },
   
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Active",
  },
  {
    name: "Test Name",
    userName: "Test user name",
    email: "Test@Test.com",
    phone: "(184) 546-64643",
    role: "Admin",
    status: "Inactive",
  },
   
];
export default tableDataComplex;
