/* eslint-disable */

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  Image,
} from "@chakra-ui/react";
// Custom components
import { get } from "redux/services/api";
import { rootUrl } from "utilities/constants";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdArrowBackIosNew } from "react-icons/md";
import logo from "assets/img/global/logo.png";
import { useHistory } from "react-router-dom";
import { Authenticate } from "redux/slices/Auth";
import { useDispatch, useSelector } from "react-redux";
function ForgotPassword() {
  // Chakra color mode
  const toast = useToast();
  const history = useHistory();
  const { userId, userName, email, phoneNumer, token } = useSelector(
    (state: any) => state.Auth
  );
  const textColor = useColorModeValue("black.50", "white");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const buttonColorBrand = useColorModeValue("blue.200", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [state, setState] = useState({
    email: "",
  });
  useEffect(() => {
    if (userId.length) {
      alert("Loging success");
    }
  }, [userId, userName, email, phoneNumer, token]);
  const [invalidState, setInvalidState] = useState({
    emailInvalid: false,

    PasswordInvalid: false,
  });
  const updateState = (val: string, prop: string) => {
    setState((prvState) => ({ ...prvState, [prop]: val }));
  };
  const checks = () => {
    if (state.email === "") {
      setInvalidState((prvState) => ({ ...prvState, emailInvalid: true }));
      return false;
    }

    return true;
  };
  const submit = async () => {
    if (checks()) {
      setIsLoading(true);

      let url = `${rootUrl}api/v1/account/forgotpassword-send-accesscode?details=${state.email}&IsSms=false&IsEmail=true&accountType=hub`;
      const response: any = await get(url);
      if (response.status === 200 && response.data.statusCode === 200) {
        toast.closeAll()
        toast({
          title: "Sent",
          description: response.data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        history.push({
          pathname: "/auth/CodeVerification",
          search: "?name=sudheer",
          state: { email: state.email },
        });
      } else {
        toast.closeAll()
        toast({
          title: "Failed",
          description: response.data.errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Box>
        <Box w={"full"} height="80px">
          <Box px={{ "2xl": "120px" }} maxW="1440px" m="auto">
            <Image src={logo} alt="logo" w={"107px"} h={"50px"} />
          </Box>
        </Box>
        <Flex
          // maxW={{ base: "100%", md: "max-content" }}
          w="578px"
          m="auto"
          h="100%"
          alignItems="center"
          // bg="red.700"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt="36px"
          p="20px"
          flexDirection="column"
          border={"1px solid #E3E4E5"}
          borderRadius="24px"
        >
          <Box m="auto">
            <Heading
              textAlign={"center"}
              color={"#298BE2"}
              fontSize="24px"
              w={400}
              mb="10px"
            >
              Forgot Your Password?
            </Heading>
            <Text
              textAlign={"center"}
              fontSize="14px"
              mt="20px"
              maxW="373"
              color={"#7A86A1"}
            >
              Enter the email address you used when you joined and we'll send
              you instructions to reset your password.
            </Text>
          </Box>
          <Flex
            mt="20px"
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl isInvalid={invalidState.emailInvalid}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Your Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                borderColor={invalidState.emailInvalid ? "red" : null}
                onChange={(e) => updateState(e.target.value, "email")}
                value={state.email}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                borderRadius={8}
                mb="10px"
                fontWeight="500"
                size="lg"
                placeholder="Tonynguyen@example.com"
              />
              {invalidState.emailInvalid && (
                <FormErrorMessage>Email is required.</FormErrorMessage>
              )}
            </FormControl>

            <Flex
              justifyContent="space-between"
              alignItems="center"
              maxW="100%"
              mt="10px"
            >
              <NavLink to="/auth/sign-in">
                <Text
                  color={buttonColorBrand}
                  // as="span"
                  // ms="5px"
                  fontWeight="700"
                  // textDecorationLine={"underline"}
                >
                  Back To Login
                </Text>
              </NavLink>

              <Button
                isLoading={isLoading}
                onClick={submit}
                fontSize="15"
                variant="brand"
                fontWeight="700"
                w="50%"
                h="50"
                mb="24px"
                borderRadius={"12px"}
                bg={buttonColorBrand}
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default ForgotPassword;
