import {
  HStack,
  Avatar,
  Box,
  Flex,
  Image,
  Grid,
  Text,
  Progress,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  useColorModeValue,
  GridItem,
} from "@chakra-ui/react";
// Assets
import lineChartRed from "assets/img/global/lineChartRed.png";
import lineChartYellow from "assets/img/global/lineChartYellow.png";
import map from "assets/img/global/map.png";

// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import Card from "components/card/Card";
import NewFollowerTable from "views/admin/analytics/components/NewFollowerTable";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex";

import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import Chart from "react-apexcharts";
import { useState } from "react";
import { VictoryClipContainer, VictoryChart, VictoryLine } from "victory";
const seriesRadial = [76];
var options1: any = {
  chart: {
    height: 350,
    type: "area",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    // type: 'datetime',
    // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
    categories: ["Mon", "Tue", "Wed", "Thr", "Fri", "Sat", "Sun"],
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
};
var options: any = {
  chart: {
    height: 390,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: "50%",
        background: "transparent",
        image: undefined,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
    },
  },
  colors: ["#FF708D", "#884DFF"],
  labels: ["Sessions", "New"],
  legend: {
    show: true,
    floating: true,
    fontSize: "16px",
    position: "left",
    // offsetX: 160,
    offsetY: -10,
    labels: {
      useSeriesColors: true,
    },
    markers: {
      size: 0,
    },
    formatter: function (seriesName: any, opts: any) {
      return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%";
    },
    itemMargin: {
      vertical: 3,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          show: false,
        },
      },
    },
  ],
};

var options_2: any = {
  chart: {
    height: 390,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: "50%",
        background: "transparent",
        image: undefined,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
    },
  },
  colors: ["#5447C8", "#877AF7"],
  labels: ["Boy", "Girls"],
  legend: {
    show: true,
    floating: true,
    fontSize: "16px",
    position: "left",
    // offsetX: 160,
    offsetY: -10,
    labels: {
      useSeriesColors: true,
    },
    markers: {
      size: 0,
    },
    formatter: function (seriesName: any, opts: any) {
      return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%";
    },
    itemMargin: {
      vertical: 3,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          show: false,
        },
      },
    },
  ],
};
export default function Index() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [state, setState] = useState<any>({
    optionsRadial: {
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#F4F7F9",
            strokeWidth: "100%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            showOn: "",
            name: {
              offsetY: -20,
              show: false,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              formatter: function (val: any) {
                return val;
              },
              color: "#62E2A6",
              fontSize: "30px",
              show: false,
            },
          },
        },
      },
      fill: {
        colors: [
          function ({ value, seriesIndex, w }: any) {
            if (value < 55) {
              return "#62E2A6";
            } else if (value >= 55 && value < 80) {
              return "#884DFF";
            } else {
              return "#000";
            }
          },
        ],
      },
      stroke: {
        lineCap: "round",
      },
      labels: [""],
    },
    seriesRadial: [43.2],
    seriesRadial2: [55.45],
  });
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem gap={4} colSpan={8} borderRadius="10px">
        <Flex gap={4} justifyContent={"space-between"}>
          <MiniStatistics
            // endContent={
            //   <Chart
            //     type="radialBar"
            //     width="100"
            //     options={state.optionsRadial}
            //     series={state.seriesRadial}
            //   />
            // }
            name="New Sessions"
            value="43.20%"
          />
          <MiniStatistics
            // endContent={
            //   <Chart
            //     type="radialBar"
            //     width="100"
            //     options={state.optionsRadial}
            //     series={state.seriesRadial2}
            //   />
            // }
            name="Bounce Rate"
            value="55.45%"
          />
          <MiniStatistics
            endContent={<Image src={lineChartRed} mt="20px" />}
            name="Average length"
            value="2 min"
          />{" "}
          <MiniStatistics
            endContent={<Image src={lineChartYellow} mt="20px" />}
            name="Session"
            value="12,352"
          />
        </Flex>
        <Flex gap={4} justifyContent={"space-between"}>
          <Card w="35%" mt="20px" borderRadius={"10px"}>
            <Text fontSize={"17px"} fontWeight="semibold">
              Audience Metrics
            </Text>
            <Text fontSize={"12px"} fontWeight="normal" color="#96A5B8">
              Lets take a look at how many users GRID has been able to get you
              in your area!
            </Text>
            <Flex justifyContent={"center"} alignItems="center">
              <Chart
                type="radialBar"
                width="300"
                height={290}
                options={options}
                series={[72, 28]}
              />
            </Flex>
            <Flex justifyContent={"space-between"} alignItems="flex-start">
              <HStack alignItems="flex-start">
                <Box w="20px" h="20px" borderRadius={"20px"} bg="#FF708D" />
                <Box>
                  <Text fontSize={"12px"} color={"#96A5B8"}>
                    Sessions
                  </Text>
                  <Text>12,342</Text>
                </Box>
              </HStack>
              <HStack alignItems="flex-start">
                <Box w="20px" h="20px" borderRadius={"20px"} bg="#884DFF" />
                <Box>
                  <Text fontSize={"12px"} color={"#96A5B8"}>
                    New
                  </Text>
                  <Text>12,342</Text>
                </Box>
              </HStack>
            </Flex>
          </Card>
          <Card w="65%" mt="20px" borderRadius={"10px"}>
            <Text fontSize={"17px"} fontWeight="semibold">
              Sessions by Channel
            </Text>

            <Flex justifyContent={"center"} alignItems="center">
              <Chart
                width="600"
                options={options1}
                series={[
                  {
                    name: "Organic Search",
                    data: [31, 40, 28, 51, 42, 109, 100],
                  },
                  {
                    name: "Events",
                    data: [11, 32, 45, 32, 34, 52, 41],
                  },
                  {
                    name: "Other",
                    data: [1, 62, 75, 82, 94, 52, 41],
                  },
                ]}
                type="area"
                height={350}
              />
            </Flex>
          </Card>
        </Flex>
        <Flex mt="20px" gap={4} justifyContent={"space-between"}>
          <NewFollowerTable tableData={tableDataComplex} />
        </Flex>
      </GridItem>
      <GridItem gap={4} colSpan={4} borderRadius="10px">
        <Card w="100%" borderRadius={"10px"}>
          <Text fontSize={"17px"} fontWeight="semibold">
            Visitor's Locations
          </Text>
          <Flex mt="20px" gap={4} justifyContent="center" alignItems="center">
            <Image src={map} mt="20px" h="244px" w="244px" />
          </Flex>
          <Text fontSize={"26px"} fontWeight="bold">
            12,883
          </Text>
          <Text color={"#96A5B8"} fontSize={"15px"} fontWeight="normal">
            Top visit by city
          </Text>
          {[["Frisco","4,303","31.2%",31],["Dallas","1,293","18.2%",18], ["Denton","895","12.6%",12], ["McKinney","343","4.8%",5], ["Allen","231","3.2%",3],["Plano","45","0.6%",1]].map((x, i) => {
            return (
              <>
                <Flex
                  key={i}
                  mt="30px"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Text fontSize={"14px"} fontWeight="semibold">
                    {x[0]}
                  </Text>
                  <Text fontSize={"14px"} fontWeight="semibold">
                    {x[1]}
                  </Text>
                  <Text fontSize={"14px"} fontWeight="semibold">
                    {x[2]}
                  </Text>
                </Flex>
                <Flex
                  mt="5px"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Slider aria-label="slider-ex-1" defaultValue={Number(x[3])}>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    {/* <SliderThumb /> */}
                  </Slider>
                </Flex>
              </>
            );
          })}

          <Text mt="20px" fontSize={"17px"} fontWeight="semibold">
            Gender Demographic
          </Text>

          <Flex justifyContent={"center"} alignItems="center">
            <Chart
              type="radialBar"
              width="300"
              height={290}
              options={options_2}
              series={[72, 28]}
            />
          </Flex>
          <Flex justifyContent={"space-between"} alignItems="flex-start">
            <HStack alignItems="flex-start">
              <Box w="20px" h="20px" borderRadius={"20px"} bg="#5447C8" />
              <Box>
                <Text fontSize={"12px"} color={"#96A5B8"}>
                  Boys
                </Text>
                <Text>12,342</Text>
              </Box>
            </HStack>
            <HStack alignItems="flex-start">
              <Box w="20px" h="20px" borderRadius={"20px"} bg="#877AF7" />
              <Box>
                <Text fontSize={"12px"} color={"#96A5B8"}>
                  Girls
                </Text>
                <Text>12,342</Text>
              </Box>
            </HStack>
          </Flex>
        </Card>
      </GridItem>
    </Grid>
  );
}
