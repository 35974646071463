import {
  HStack,
  Avatar,
  Box,
  Flex,
  Image,
  Grid,
  Text,
  Progress,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderMark,
  useColorModeValue,
  GridItem,
  Icon,
} from "@chakra-ui/react";
// Assets
import lineChartRed from "assets/img/global/lineChartRed.png";
import lineChartYellow from "assets/img/global/lineChartYellow.png";
import Graph from "assets/img/global/Graph.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import Card from "components/card/Card";
import NewFollowerTable from "views/admin/analytics/components/NewFollowerTable";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex";

import EventInsights from "views/admin/analytics/components/EventInsights";
import GamesCard from "views/admin/analytics/components/GamesCard";
import EventEngagementCard from "views/admin/analytics/components/EventEngagementCard";

import {
  BsArrowUpRightCircleFill,
  BsArrowDownRightCircleFill,
  BsFillTriangleFill,
} from "react-icons/bs";
import Chart from "react-apexcharts";
import { useState } from "react";
import { HSeparator } from "components/separator/Separator";
import haloAvtar from "assets/img/global/Ellipse 21.png";
import pubgAvtar from "assets/img/global/pubgAvtar.png";
import fortAvtar from "assets/img/global/Ellipse 22 (1).png"
import OverAvatar from "assets/img/global/Ellipse 21 (1).png"
import AssassAvatar from "assets/img/global/Ellipse 22.png"
import ValorantAvatar from "assets/img/global/Ellipse 21 (2).png"
const seriesRadial = [76];

var options1: any = {
  chart: {
    type: "bar",
    height: 350,
  },
  colors: ["#64C8BC", "#EDF8F6", "#19BAEE"],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
  },
  yaxis: {
    title: {
      text: "$ (thousands)",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val: any) {
        return "$ " + val + " thousands";
      },
    },
  },
};

var options_2: any = {
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: "top",
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetX: -6,
    style: {
      fontSize: "12px",
      colors: ["#fff"],
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    shared: true,
    intersect: false,
  },
  xaxis: {
    categories: ["13-17", "18-24", "25-34", "35-44", "45++"],
  },
  colors: ["#19BAEE", "#FF5247"],
};
export default function Index() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [state, setState] = useState<any>({
    optionsRadial: {
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#F4F7F9",
            strokeWidth: "100%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            showOn: "",
            name: {
              offsetY: -20,
              show: false,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              formatter: function (val: any) {
                return val;
              },
              color: "#62E2A6",
              fontSize: "30px",
              show: false,
            },
          },
        },
      },
      fill: {
        colors: [
          function ({ value, seriesIndex, w }: any) {
            if (value < 55) {
              return "#62E2A6";
            } else if (value >= 55 && value < 80) {
              return "#884DFF";
            } else {
              return "#000";
            }
          },
        ],
      },
      stroke: {
        lineCap: "round",
      },
      labels: [""],
    },
    seriesRadial: [43.2],
    seriesRadial2: [55.45],
  });
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem gap={4} colSpan={8} borderRadius="10px">
        <Flex gap={4} justifyContent={"space-between"}>
          <EventInsights />
        </Flex>
        <Flex mt="20px" gap={4} justifyContent={"space-between"}>
          <Text fontSize={"18px"} color="#707070" fontWeight="bold">
            Your followers seem to enjoy these games this month
          </Text>
        </Flex>
        <Flex flexWrap={"nowrap"} gap={4} justifyContent={"space-between"}>
          <GamesCard
            handleNext={() => null}
            btn_2_txt="Remove"
            btn_1_txt="Use Template"
            cardBg="white"
            heading="PUBG Championship"
            iconName={BsArrowUpRightCircleFill}
            image={pubgAvtar}
            rank="8.4"
            clicks="+20.5%"
          />
          <GamesCard
            handleNext={() => null}
            btn_2_txt="Remove"
            btn_1_txt="Use Template"
            cardBg="white"
            heading="Halo: Infinity"
            iconName={BsArrowUpRightCircleFill}
            image={haloAvtar}
            rank="6.8"
            clicks="+18.3%"
          />
          <GamesCard
            handleNext={() => null}
            btn_2_txt="Remove"
            btn_1_txt="Use Template"
            cardBg="white"
            heading="Fortnite"
            iconName={BsArrowUpRightCircleFill}
            image={fortAvtar}
            rank="7.2"
            clicks="+19.7%"
          />
        </Flex>
        <Flex flexWrap={"nowrap"} gap={4} justifyContent={"space-between"}>
          <GamesCard
            handleNext={() => null}
            btn_2_txt="Remove"
            btn_1_txt="Use Template"
            cardBg="white"
            heading="Overwatch"
            iconName={BsArrowUpRightCircleFill}
            image={OverAvatar}
            rank="5.9"
            clicks="+16.2%"
          />
          <GamesCard
            handleNext={() => null}
            btn_2_txt="Remove"
            btn_1_txt="Use Template"
            cardBg="white"
            heading="AssassAvatar"
            iconName={BsArrowUpRightCircleFill}
            image={AssassAvatar}
            rank="6.8"
            clicks="+22.1%"
          />
          <GamesCard
            handleNext={() => null}
            btn_2_txt="Remove"
            btn_1_txt="Use Template"
            cardBg="white"
            heading="Valorant"
            iconName={BsArrowUpRightCircleFill}
            image={ValorantAvatar}
            rank="4.3"
            clicks="+18.5%"
          />
        </Flex>
        <Flex mt="20px">
          <Card w="100%" borderRadius={"10px"}>
            <Text fontSize={"17px"} fontWeight="semibold">
              Highest Event Engagement
            </Text>

            <Text>
              Quickly see which 4 events have the best engagement from your
              followers in this month
            </Text>

            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              py="20px"
            >
              <Box w="48%">
                <EventEngagementCard />
              </Box>
              <Box w="48%">
                <EventEngagementCard />
              </Box>
            </Flex>
          </Card>
        </Flex>
      </GridItem>
      <GridItem gap={4} colSpan={4} borderRadius="10px">
        <Card px="0px">
          <Flex px="20px" direction={"column"}>
            <Text fontSize={"16px"} fontWeight="normal">
              Recent Payment Activity
            </Text>

            <HStack
              color={"#96A5B8"}
              justifyContent={"space-between"}
              mt="20px"
            >
              <Text w="33.33%" fontSize={"16px"} fontWeight="normal">
                Name
              </Text>
              <Text w="33.33%" fontSize={"16px"} fontWeight="normal">
                Amount
              </Text>
              <Text w="33.33%" fontSize={"16px"} fontWeight="normal">
                Event
              </Text>
            </HStack>
          </Flex>
          <HSeparator />
          <Flex px="20px" direction={"column"}>
            {["Cody Lewis", "Alex Lue", "Hassan","Hamza", "Daniyal", "Hasnain", "Raees"].map((x) => {
              return (
                <HStack justifyContent={"space-between"} mt="20px">
                  <Text
                    w="33.33%"
                    color={"#222B45"}
                    fontSize={"16px"}
                    fontWeight="normal"
                  >
                    {x}
                  </Text>
                  <Text
                    w="33.33%"
                    color={"#31D0AA"}
                    fontSize={"16px"}
                    fontWeight="normal"
                  >
                    +$20.5
                  </Text>
                  <Text
                    w="33.33%"
                    color={"#298BE2"}
                    fontSize={"16px"}
                    fontWeight="normal"
                  >
                    Link
                  </Text>
                </HStack>
              );
            })}
          </Flex>
        </Card>
        <Flex mt={"20px"} gap={5} justifyContent={"space-between"}>
          <Card>
            <Flex
              direction={"column"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Text fontSize={"38px"} fontWeight="medium">
                $14,380
              </Text>
              <Text fontSize={"12px"} fontWeight="normal">
                Total Revenue
              </Text>
              <HStack mt="30px">
                <Icon
                  as={BsArrowUpRightCircleFill}
                  color={"#64C8BC"}
                  cursor="pointer"
                  boxSize={"30px"}
                  // size={"sm"}
                  //    p='5px'
                  borderRadius={"5px"}
                />

                <Text fontSize={"23px"} fontWeight="normal" color="#64C8BC">
                  26.84%
                </Text>
              </HStack>
            </Flex>
          </Card>
          <Card>
            <Flex
              direction={"column"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Text fontSize={"38px"} fontWeight="medium">
                $2,809
              </Text>
              <Text fontSize={"12px"} fontWeight="normal">
                Total Loss
              </Text>
              <HStack mt="30px">
                <Icon
                  as={BsArrowDownRightCircleFill}
                  color={"#FF5247"}
                  cursor="pointer"
                  boxSize={"30px"}
                  // size={"sm"}
                  //    p='5px'
                  borderRadius={"5px"}
                />

                <Text fontSize={"23px"} fontWeight="normal" color="#FF5247">
                  26.84%
                </Text>
              </HStack>
            </Flex>
          </Card>
        </Flex>
        <Flex mt={"20px"} gap={5} justifyContent={"space-between"}>
          <Card>
            <Text fontSize={"17px"} fontWeight="bold">
              Event Summary
            </Text>
            <Text fontSize={"12px"} fontWeight="normal">
              Total of likes & comments
            </Text>
            <Flex mt='20px'>
              <MiniStatistics
                // startContent={
                //   <Chart
                //     type="radialBar"
                //     width="100"
                //     options={state.optionsRadial}
                //     series={state.seriesRadial}
                //   />
                // }
                name="Likes"
                value="2.864"
              />
              <MiniStatistics
                //   startContent={
                //   <Chart
                //     type="radialBar"
                //     width="100"
                //     options={state.optionsRadial}
                //     series={state.seriesRadial}
                //   />
                // }
                name="Comments"
                value="624"
              />
            </Flex>
          </Card>
        </Flex>
      </GridItem>
    </Grid>
  );
}
